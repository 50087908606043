import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import TagInput from '@emcasa/ui-dom/components/TagInput';
import View from '@emcasa/ui-dom/components/View';
import Text from '@emcasa/ui-dom/components/Text';
import TagSearch from './TagSearch';
import tags from './tags.json';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "taginput"
    }}>{`TagInput`}</h1>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<TagInput options={tags} onChange={value => console.log(\'selected\', value)} />'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      TagInput,
      View,
      Text,
      TagSearch,
      tags
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <TagInput options={tags} onChange={value => console.log('selected', value)} mdxType="TagInput" />
    </Playground>
    <h2 {...{
      "id": "with-search-input"
    }}>{`With search input`}</h2>
    <Playground __position={1} __code={'<TagSearch tags={tags}>\n  {({ loading, search, results, onChange }) => (\n    <TagInput\n      placeholder=\"Search tags\"\n      options={results && results.length > 0 ? results : tags}\n      onChangeText={onChange}\n    >\n      {loading ? (\n        <View mr=\"15px\" ml=\"15px\">\n          <Text>Loading...</Text>\n        </View>\n      ) : results.length ? (\n        undefined\n      ) : search.length ? (\n        <View mr=\"15px\" ml=\"15px\">\n          <Text>No results for \"{search}\"</Text>\n        </View>\n      ) : (\n        <View mr=\"15px\" ml=\"15px\">\n          <Text>Start typing to search tags</Text>\n        </View>\n      )}\n    </TagInput>\n  )}\n</TagSearch>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      TagInput,
      View,
      Text,
      TagSearch,
      tags
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <TagSearch tags={tags} mdxType="TagSearch">
    {({
          loading,
          search,
          results,
          onChange
        }) => <TagInput placeholder="Search tags" options={results && results.length > 0 ? results : tags} onChangeText={onChange} mdxType="TagInput">
        {loading ? <View mr="15px" ml="15px" mdxType="View">
            <Text mdxType="Text">Loading...</Text>
          </View> : results.length ? undefined : search.length ? <View mr="15px" ml="15px" mdxType="View">
            <Text mdxType="Text">No results for "{search}"</Text>
          </View> : <View mr="15px" ml="15px" mdxType="View">
            <Text mdxType="Text">Start typing to search tags</Text>
          </View>}
      </TagInput>}
  </TagSearch>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      